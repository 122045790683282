import * as React from "react";
import useStoryblok from "../hooks/useStoryblok.hook";
import renderBloks from "../utils/renderBloks";
import Layout from "../components/Layout/Layout.component";
import { isEditMode } from "../utils/isEditMode";
import getMetaData from "../utils/getMetaData";

const PageNotFound = () => {
  return <div>404. Page Not Found</div>;
};

function PreviewPageCatchAll({ location }) {
  if (!isEditMode()) return <PageNotFound />;

  const story = useStoryblok(null, location);

  if (!story) {
    return (
      <div className="py-8">
        <p className="italic text-center">Loading Preview...</p>
      </div>
    );
  }

  const blocks = renderBloks(story?.content?.body || []);
  const seo = getMetaData(story?.content);

  return (
    <Layout metaData={seo} location={location}>
      {blocks}
    </Layout>
  );
}

export default PreviewPageCatchAll;
